body {
    margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
}

/* Poppins Font Setup */
@font-face {
    font-family: 'Poppins-ExtraLight';
    font-weight: 300;
    src: local('Poppins-ExtraLight'), url(./assets/fonts/Poppins-ExtraLight.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    font-weight: 300;
    src: local('Poppins-Light'), url(./assets/fonts/Poppins-Light.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    font-weight: 500;
    src: local('Poppins-Medium'), url(./assets/fonts/Poppins-Medium.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    src: local('Poppins-SemiBold'), url(./assets/fonts/Poppins-SemiBold.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    font-weight: 700;
    src: local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    font-weight: 800;
    src: local('Poppins-ExtraBold'), url(./assets/fonts/Poppins-ExtraBold.otf) format('truetype');
}

/* Poppins Font Setup */


#actionButtonLogin {
    background-color: #FFFFFF;
    border-color: #12B3ED;
    color: #12B3ED;
    min-width: 90px;
    min-height: 40px;
    border-width: 2px;
    border-radius: 8px;
    font-family: 'Poppins-SemiBold';
    transition: background-color 0.3s, color 0.3s;
    /* Add smooth transition */

    /* Hover styles */
    &:hover {
        background-color: #12B3ED;
        color: #FFFFFF;
    }
}

#actionButton {
    background-color: #12B3ED;
    color: #FFFFFF;
    min-width: 90px;
    min-height: 40px;
    border-width: 2px;
    border-radius: 8px;
    font-family: 'Poppins-Medium';
}

.css-1nmdiq5-menu {
    z-index: 100 !important;
}
.custom-marker-icon {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: tomato !important;
    font-size: 30px;
}

#cardLogin {
    background-color: #000A2E;
    min-height: 30vh;
    min-width: 70vw;
    border-radius: 15px;
    color: white;
    border: none;
}

#cardResetPassword {
    background-color: #FFFFFF;
    min-height: 30vh;
    min-width: 550px;
    border-radius: 15px;
    /* color: #FFFFFF; */
    border: none;
}

.btnAction {
    height: 150px;
    border: none !important;
    min-width: 350px;
    border-radius: 100px !important;
    font-family: 'Poppins-SemiBold' !important;
    font-size: 20px !important;
    text-align: center;
    text-transform: uppercase;
    transition: 0.8s !important;
    background-size: 200% auto;
    color: white !important;
    /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btnAction:hover {
    background-position: right center;
    /* change the direction of the change here */
}

.btnAction-1 {
    background-image: linear-gradient(to right, #f6d365 0%,
            #fda085 51%,
            #f6d365 100%);
}

.btnAction-2 {
    background-image: linear-gradient(to right, #fd85c1 0%,
            #ba65f6 51%,
            #fd85c1 100%);
}
.btnAction-3 {
    background-image: linear-gradient(to right, #84fab0 0%,
            #8fd3f4 51%,
            #84fab0 100%);
}

.btnAction-4 {
    background-image: linear-gradient(to right, #85b5fd 0%,
            #4430ff 51%,
            #85b5fd 100%);
}
a:hover {
    color: #12B3ED !important;
}

.nav-link {
    color: #000A2E !important;
    font-family: 'Poppins-Regular';
}


#basic-nav-dropdown {
    background-color: transparent !important;
    color: #FFFFFF !important
}
.nav-link:hover {
    background-color: #000A2E !important;
    color: #FFFFFF !important;
    font-family: 'Poppins-Regular';
}

.nav-tabs .nav-link.active {
    background-color: #000A2E !important;
    color: #FFFFFF !important;
    font-family: 'Poppins-Regular';
}

.invalid-feedback {
    font-family: 'Poppins-Light';
}

#notFoundPage {
    background-color: #000A2E;
    min-height: 100vh;
    background-image: url(./assets/images/notfound.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* Center the background image */
}

.swal2-title {
    font-family: 'Poppins-Medium' !important;
}

.swal2-html-container {
    font-family: 'Poppins-Light' !important;
}

.swal2-confirm {
    font-family: 'Poppins-Regular' !important;
    background-color: #12B3ED !important;
    box-shadow: none !important;
}

.swal2-cancel {
    font-family: 'Poppins-Regular' !important;
    box-shadow: none !important;
}
/* Mantine Table Styling */

.mantine-1urdy5i {
    min-width: 280px !important;
}
.mantine-1f8idjp {
    min-width: 100%;
    font-family: 'Poppins-Medium' !important
}

.mantine-gxgfw {
    min-width: 100%;
    font-family: 'Poppins-Medium' !important
}

.mantine-h1g4yy {
    font-family: 'Poppins-Regular' !important
}

.mantine-1d564l0 {
    font-family: 'Poppins-Regular' !important
}

.mantine-1vq8ow9 {
    font-family: 'Poppins-Regular' !important
}

.mantine-6kqrk6 {
    font-family: 'Poppins-Regular' !important
}

.mantine-1bauqbv {
    font-family: 'Poppins-Regular' !important
}

.mantine-1cn2mlo {
    font-family: 'Poppins-Regular' !important
}

/* Mantine Table Styling */


/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
    #notFoundPage {
        background-size: contain;
        /* Adjust background size for smaller screens */
    }
#cardResetPassword {
    min-width: 350px;
}
}